export const automotiveBusiness = {
  '@context': 'https://schema.org',
  '@type': 'AutomotiveBusiness',
  name: 'instacar',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Palaia Tatoioi 165Γ',
    addressLocality: 'Athina',
    addressRegion: 'GR',
    postalCode: '145 64',
  },
  image:
    'https://lh5.googleusercontent.com/p/AF11QipMHMyqc2_udqWftsXVvIe4fMeraMGov5Z3giS4c=w334-h100-k-no',
  telePhone: '+30 2106107283',
  url: 'https://www.instacar.gr/',
  paymentAccepted: ['credit card', 'bank transfer'],
  openingHoursSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '09:30',
      closes: '19:30',
    },
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Saturday'],
      opens: '10:00',
      closes: '15:00',
    },
  ],
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '38.102887',
    longitude: '23.8116583',
  },
}
